<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">分类列表</div>
        <el-button type="primary" @click="addClass" class="cardTitleBtn">新建分类</el-button>
      </el-col>
      <el-col :span="24">
        <el-table :data="drugClassList" style="width: 100%">
            <el-table-column prop="name" label="名称" sortable></el-table-column>
            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button @click="getDetail(scope.row)" type="success" size="mini">药品列表</el-button>
                    <el-button @click="editClass(scope.row)" type="primary" size="mini">编辑</el-button>
                    <el-button @click="onDelete(scope.row)" type="danger" size="mini">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog title="分类名称" width="30%" :visible.sync="classTypeVisible" >
        <el-form>
            <el-form-item>
                <el-input v-model="className"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="classTypeVisible = false">取 消</el-button>
            <el-button type="primary" @click="addAndEditClass">确 定</el-button>
        </div>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      drugClassList:[],
      classForm: {
        guid: '',
        name: ''
      },
      classTypeVisible: false,
      className: ''
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getDrugClassList()
  },
  methods: {
    // 获取药品分类列表
    getDrugClassList() {
      let self = this;
      self.$axios
        .get("/api/medicine-category/list", {
          headers: { token: self.userInfo.token }
        })
        .then(function (response) {
          self.drugClassList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "分类列表获取失败："+error.response.data.message,
            type: "warning",
          });
        });
    },
    // 添加分类
    addClass() {
      let self = this
      self.className = ''
      self.classTypeVisible = true
    },
    // 编辑分类
    editClass(row) {
      let self = this
      self.className = row.name
      self.classForm.guid = row.guid
      self.classTypeVisible = true
    },
    // 确认添加或编辑分类
    addAndEditClass() {
      let self = this
      self.classForm.name = self.className
      self.$axios.post('/api/medicine-category/saveOrUpdate',self.classForm,{
        headers: { token: self.userInfo.token }
      })
      .then(function() {
        self.$message({
          type: 'success',
          message: '添加成功'
        })
        self.getDrugClassList()
        self.classTypeVisible = false
      })
      .catch(function(err) {
        self.$message({
            message: "添加失败："+err.response.data.message,
            type: "warning",
          });
      })
    },
    // 删除
    onDelete(row) {
      let self = this;
      self
        .$confirm("确定删除 " + (row.name || "未命名") + "？")
        .then(() => {
          self.$axios
            .delete("/api/medicine-category/delete", {
              data: { guid: row.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.getDrugClassList()
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    // 分类下详情
    getDetail(row) {
      let self = this;
      self.$router.push({ name: "drugAdministrationDrugClassDetail", query: { guid: row.guid, name: row.name } });
    }
  },
};
</script>
